@mixin __FF_SAME_ORIGIN_GRAPHQL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ADD_TO_CART_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_IN_HEAD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_BOTIFY_ASYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_INVENTORY_VEHICLEINFO__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_CHECK_WIPER_AVAILABILITY_FOR_STORE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GEOPOINT_DIRECTIONS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_EMPLOYEE__DISCOUNT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GK_ALLOW_OPTIONAL_AND_PARTIAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_INSTANT_PRICE_STRIKE_THROUGH__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_IS_APPOINTMENT_STANDARD_QUOTE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_KEYWORD_REDIRECT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_NO_STORE_IN_AREA_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_LICENSE_LOOKUP_FIELDS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_CART_ACCOUNT_DRAWER__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_MY_ACCOUNT_($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PERSIST_MULTISTORE_PLP_PRODUCT_TO_PDP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_PDP_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ONEDT_TREADWELL_DATA_NONTREADWELL_PLP__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_OPTIONAL_FITMENT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_ORDER_CONFIRMATION_SURVEY_INTEGRATION__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PARTYTOWN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_360_WHEEL_VIEW__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STANDARD__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_STAGGERED__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_PREBUILT_PACKAGES__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PDP_NEARBY_STORE_STOCK_CANONICAL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PREFETCH_APPOINTMENTS__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_COMPARE_WITH_CONTEXT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_IMAGE_FORMATS_AUTO_SYNC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PRODUCT_RECONCILIATION_CALL__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_RENAME_TREADWELL_CONTENT_QUERY_($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SALESFORCE_CHAT__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SERVICE_APPOINTMENT_REDESIGN__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_SESSION_TIMEOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SOURCE_DEFENSE__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_SHOP_BY_BRAND_DYNAMIC__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_STORE_IN_DIFFERENT_STATE_DRAWER__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TIRES_THAT_DONT_FIT_MESSAGING__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_TREADWELL_LANDING_PAGE_CMS__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_UTAG_SYNC__($toggle: true) {
          @if false and $toggle == true {
            @content;
          } @else if true and $toggle == false {
            @content;
          }
        }
@mixin __FF_WHEEL_VISUALIZER_REDESIGN__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_AFFIRM_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_APPLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_GOOGLE_PAY_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@mixin __FF_PAYPAL_CHECKOUT__($toggle: true) {
          @if true and $toggle == true {
            @content;
          } @else if false and $toggle == false {
            @content;
          }
        }
@import '../../shared/scss/_dt-base.scss';

.container {
  // needed to ensure scrollIntoView doesn't scroll the window.
  position: relative;
}

%toggle {
  position: relative;
  margin: 20px -10px 0 0;
  padding: 0;
  height: 30px;
  width: 30px;
  background: transparent;

  &::before {
    content: '';
    position: absolute;
    top: 14px;
    left: 7px;
    width: 15px;
    height: 1px;
    background: $dt-blue;
  }

  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 14px;
    width: 1px;
    height: 15px;
    background: $dt-blue;
  }
}

%list-item {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 20px 16px;
  border-bottom: 1px solid $grey-300;
  overflow: hidden;
  transition: height 0.3s;

  &:last-child {
    border: 0;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15px;
    background: $white;
  }

  @include media($min-md) {
    padding: 20px;
  }

  :global {
    .collapsible-toggle {
      padding-left: 0;
    }

    .store-quick-view__hours-details {
      margin-bottom: 15px;
    }
  }
}

.list-item {
  @extend %list-item;
}

.list-item-minimal {
  @extend %list-item;
  height: 112px;
}

.list-item--open {
  height: 265px;
}

.list-item--selected {
  border-left: 5px solid $red-60;

  &:last-child {
    border-left: 5px solid $red-60;
  }
}

.list-index {
  min-width: 30px;
}

.list-number {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3px 8px 0 0;
  width: 22px;
  height: 22px;
  border: 2px solid $grey-600;
  border-radius: 50%;
  color: $black;
}

.list-actions {
  margin-left: 7px;
}

.toggle {
  @extend %toggle;
}

.toggle-expanded {
  @extend %toggle;

  &::after {
    display: none;
  }
}

.services-message-container {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  background-color: $green-50;
  color: $dt-black;
  margin: -20px 0px 20px -20px;
  width: 100%;
  min-width: 520px;
}

.icon {
  margin-right: 16px;
  font-size: rem(16);
  color: $green-80;
}

.services-message {
  font-size: rem(12);
  color: $dt-black;
}

.quick-view-container {
  display: flex;
  width: 100%;
}
